<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("device.hd.text"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      stockinfo: [],
      devicesearch: "",
      getdeviceslist: "",
      selectdevicestatus: "-1",
      jurisdiction: [],
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      deviceid: 0,
      hdinfo: [],
      subid: 0,
      goodslist: [],
      goodsallcount: 0,
      goodsallpage: 0,
      goodspage: 1,
      id: 0,
    };
  },
  mounted() {
    this.title = this.$t("device.hd.text");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.lists"),
        href: "/device/list",
      },
      {
        text: this.$t("menuitems.devices.devicedetail"),
        href: "/device/devicedetail?id=" + this.$route.query.id,
      },
      {
        text: this.$t("device.hd.text"),
        active: true,
      },
    ];
    //   this.titleTemplate(this.$t('menuitems.devices.lists'))
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    this.getgoodslist();
    this.gethdinfo();
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.deviceid = this.$route.query.id;
    //   this.page.title=this.$t('menuitems.devices.lists')
  },
  methods: {
    gethdinfo() {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "gethdinfo",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.hdinfo = response.data.data.hd;
          that.subid = response.data.data.subcode;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getgoodslist() {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "goodslist",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.goodslist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addhd() {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "addhd",
            id: that.$route.query.id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
    save(key, hid, e) {
      var that = this;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "changehdinfo",
            hid: hid,
            key: key,
            value: e.target.value,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.change.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.change.fail"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deletes: function (type, id) {
      var that = this;
      if (type == "hd") {
        that
          .$confirm(
            that.$t("global.delete.deletehdtip"),
            that.$t("global.delete.sureyouaction"),
            {
              confirmButtonText: that.$t("global.button.ok"),
              cancelButtonText: that.$t("global.button.cancel"),
              type: "warning",
            }
          )
          .then(() => {
            that.$axios
              .post(
                "https://admin.aicoiot.beer/api.json",
                {
                  action: "delete",
                  type: type,
                  id: id,
                },
                {
                  headers: { Openid: that.userinfo.openid },
                }
              )
              .then(function (response) {
                if (response.data.status == 200) {
                  that.$message({
                    message: that.$t("global.delete.success"),
                    type: "success",
                  });
                } else if (response.data.status == 403) {
                  that.$message({
                    message: that.$t("global.delete.auth"),
                    type: "warning",
                  });
                } else {
                  that.$message.error(that.$t("global.delete.500"));
                }
                that.gethdinfo();
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch(() => {});
      }
    },
    openboolcommand: function (type, id) {
      var that = this;
      if (type == "hd") {
        that.$axios
          .post(
            "https://admin.aicoiot.beer/api.json",
            {
              action: "beerdoor",
              id: id,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            if (response.data.status == 200) {
              that.$message({
                message: that.$t("global.success"),
                type: "success",
              });
            } else {
              that.$message.error(that.$t("global.failed"));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    openwithcommand: function (type, id) {
      var that = this;
      if (type == "hd") {
        that.$axios
          .post(
            "https://admin.aicoiot.beer/api.json",
            {
              action: "gethdinfo_single",
              id: id,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            if (response.data.status == 200) {
              that.$axios
                .post("https://admin.aicoiot.beer/openapi.json", {
                  action: "com.device.beer.open",
                  sn: response.data.key,
                  hdno: response.data.data.no,
                  payway: 0,
                  total: response.data.data.stock,
                  price: response.data.data.price,
                })
                .then(function (response) {
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("global.success"),
                      type: "success",
                    });
                  } else {
                    that.$message.error(that.$t("global.failed"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              that.$message.error(that.$t("global.failed"));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    closewithcommand: function (type, id) {
      var that = this;
      if (type == "hd") {
        that.$axios
          .post(
            "https://admin.aicoiot.beer/api.json",
            {
              action: "gethdinfo_single",
              id: id,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            if (response.data.status == 200) {
              that.$axios
                .post("https://admin.aicoiot.beer/openapi.json", {
                  action: "com.device.beer.close",
                  sn: response.data.key,
                })
                .then(function (response) {
                  if (response.data.status == 200) {
                    that.$message({
                      message: that.$t("global.success"),
                      type: "success",
                    });
                  } else {
                    that.$message.error(that.$t("global.failed"));
                  }
                })
                .catch(function (error) {
                  console.log(error);
                });
            } else {
              that.$message.error(that.$t("global.failed"));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    savecommand: function (type, id) {
      var that = this;
      if (type == "hd") {
        that.$axios
          .post(
            "https://admin.aicoiot.beer/api.json",
            {
              action: "savedeviceinfosetting",
              id: id,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            if (response.data.status == 200) {
              that.$message({
                message: that.$t("global.success"),
                type: "success",
              });
            } else {
              that.$message.error(that.$t("global.failed"));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>{{ $t("device.hd.no") }}</th>
                  <th>{{ $t("device.hd.name") }}</th>
                  <th>{{ $t("device.hd.stock") }}</th>
                  <th>{{ $t("device.hd.price") }}</th>
                  <th>{{ $t("device.hd.sales") }}</th>
                  <th>{{ $t("device.hd.diff") }}</th>
                  <th>{{ $t("device.hd.unit") }}</th>
                  <th>{{ $t("device.hd.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in hdinfo" :key="idx">
                  <td>{{ val.no }}</td>
                  <td v-if="subid != 7610">
                    <select
                      name="goods"
                      class="form-control"
                      :id="'gid' + val.id"
                      @change="save('gid', val.id, $event)"
                    >
                      <option value="0" v-if="val.gid == 0">
                        {{ $t("device.hd.select") }}
                      </option>
                      <option
                        v-for="(v, i) in goodslist"
                        :value="v.id"
                        :key="i"
                        :selected="v.id == val.gid"
                      >
                        {{ v.title }}
                      </option>
                    </select>
                  </td>
                  <td v-if="subid != 7610">
                    <input
                      type="number"
                      class="form-control"
                      :value="val.stock"
                      :id="'stock' + val.id"
                      @change="save('stock', val.id, $event)"
                    />
                  </td>
                  <td v-if="subid != 7610">
                    <input
                      type="text"
                      class="form-control"
                      :value="val.price"
                      :id="'price' + val.id"
                      @change="save('price', val.id, $event)"
                    />
                  </td>
                  <td v-if="subid != 7610">
                    <input
                      type="text"
                      class="form-control"
                      :value="val.sale_price"
                      :id="'sale_price' + val.id"
                      @change="save('sale_price', val.id, $event)"
                    />
                  </td>
                  <td v-if="subid != 7610">
                    <input
                      type="number"
                      class="form-control"
                      :value="val.diff"
                      :id="'diff' + val.id"
                      @change="save('diff', val.id, $event)"
                    />
                  </td>
                  <td v-if="subid != 7610">
                    <select
                      name="unity"
                      class="form-control"
                      :id="'unity' + val.id"
                      @change="save('unity', val.id, $event)"
                    >
                      <option value="0">ML</option>
                      <option value="1">CC</option>
                      <option value="3">{{ $t("device.hd.cup") }}</option>
                      <option value="4">{{ $t("device.hd.bottle") }}</option>
                    </select>
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-success btn-sm rounded-pill"
                      @click="openwithcommand('hd', val.id)"
                      v-if="subid == 7500"
                    >
                      {{ $t("device.hd.send") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-sm rounded-pill"
                      @click="openwithcommand('hd', val.id)"
                      v-if="subid == 2100"
                    >
                      {{ $t("device.hd.send") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm rounded-pill"
                      @click="closewithcommand('hd', val.id)"
                      v-if="subid == 7500"
                    >
                      {{ $t("device.hd.close") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-success btn-sm rounded-pill"
                      @click="openwithcommand('hd', val.id)"
                      v-if="subid == 7620"
                    >
                      {{ $t("device.hd.send") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-info btn-sm rounded-pill"
                      @click="savecommand('hd', val.id)"
                      v-if="subid == 7620"
                    >
                      {{ $t("device.hd.save") }}
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger btn-sm rounded-pill"
                      @click="deletes('hd', val.id)"
                      v-if="subid != 7610 && subid != 7620"
                    >
                      {{ $t("device.lists.delete") }}
                    </button>
                  </td>
                </tr>
                <tr v-if="subid == 7620">
                  <td>5</td>
                  <td colspan="6" style="text-align: center; cursor: pointer">
                    {{ $t("device.hd.bottleroute") }}
                  </td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-success btn-sm rounded-pill"
                      @click="openboolcommand('hd', id)"
                      v-if="subid == 7620"
                    >
                      {{ $t("device.hd.send") }}
                    </button>
                    <!-- <button
                      type="button"
                      class="btn btn-info btn-sm rounded-pill"
                      @click="managebto(id)"
                      v-if="subid == 7620"
                    >
                      {{ $t("device.hd.setting") }}
                    </button> -->
                  </td>
                </tr>
                <tr v-if="subid == 7610">
                  <td
                    colspan="6"
                    style="text-align: center; cursor: pointer"
                    @click="addhd(id)"
                  >
                    {{ $t("device.hd.add") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>